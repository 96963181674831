import { __ } from '@wordpress/i18n';

import './downCount.scss';

const downCount = (container, options, callback) => {
	const settings = Object.assign({ date: null, offset: null }, options);

	const getText = (sl) => container.querySelector(sl).textContent;
	const setText = (sl, val) => {
		container.querySelector(sl).textContent = val;
	}

	if (!settings.date) {
		throw new Error('Date is not defined.');
	}

	if (!Date.parse(settings.date)) {
		throw new Error('Incorrect date format, it should look like this, 12/24/2012 12:00:00.');
	}

	const currentDate = () => {
		// get the client's current date
		const date = new Date();

		// turn the date to UTC
		const utc = date.getTime() + (date.getTimezoneOffset() * 60000);

		return new Date(utc + (3600000 * settings.offset)); // return a new Date object
	}

	function countdown() {
		// difference of dates
		const difference = new Date(settings.date) - currentDate();

		// if the difference is negative, then it's past the target date
		if (difference < 0) {
			// stop the timer
			clearInterval(interval);

			if (callback && typeof callback === 'function') {
				callback();
			}

			return;
		}

		// basic math variables
		const _second = 1000;
		const _minute = _second * 60;
		const _hour = _minute * 60;
		const _day = _hour * 24;

		// calculate dates
		var days = Math.floor(difference / _day);
		var hours = Math.floor((difference % _day) / _hour);
		var minutes = Math.floor((difference % _hour) / _minute);
		var seconds = Math.floor((difference % _minute) / _second);

		// fix dates so that they will show two digits
		days = (String(days).length >= 2) ? days : '0' + days;
		hours = (String(hours).length >= 2) ? hours : '0' + hours;
		minutes = (String(minutes).length >= 2) ? minutes : '0' + minutes;
		seconds = (String(seconds).length >= 2) ? seconds : '0' + seconds;

		// based on the date change the reference wording
		var refDays = days === '01' ? __('day', 'header-promo') : __('days', 'header-promo');
		var refHours = hours === '01' ? __('hour', 'header-promo') : __('hours', 'header-promo');
		var refMinutes = minutes === '01' ? __('minute', 'header-promo') : __('minutes', 'header-promo');
		var refSeconds = seconds === '01' ? __('second', 'header-promo') : __('seconds', 'header-promo');

		// set the values to the DOM
		setText('.days', days);
		setText('.hours', hours);
		setText('.minutes', minutes);
		setText('.seconds', seconds);

		getText('.days_ref') === 'days' && setText('.days_ref', refDays);
		getText('.hours_ref') === 'hours' && setText('.hours_ref', refHours);
		getText('.minutes_ref') === 'minutes' && setText('.minutes_ref', refMinutes);
		getText('.seconds_ref') === 'seconds' && setText('.seconds_ref', refSeconds);
	}

	// start
	var interval = setInterval(countdown, 1000);

	// Return the interval for later use or stopping the countdown if needed
	return interval;
}
export default downCount;

// Example of usage:
// var countdownContainer = document.querySelector('.countdown-container');
// var options = {
// 	date: '12/24/2023 12:00:00', // Specify your target date here
// 	offset: 0, // Set your desired offset
// };

// var interval = downCount(countdownContainer, options, function () {
// 	// Callback function when the countdown is complete
// 	console.log('Countdown is complete!');
// });