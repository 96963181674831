import downCount from './downCount';
import './style.scss';

const slideUp = (target, duration = 500) => {
	target.style.transitionProperty = 'height, margin, padding';
	target.style.transitionDuration = duration + 'ms';
	target.style.boxSizing = 'border-box';
	target.style.height = target.offsetHeight + 'px';
	target.offsetHeight;
	target.style.overflow = 'hidden';
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	window.setTimeout(() => {
		target.style.display = 'none';
		target.style.removeProperty('height');
		target.style.removeProperty('padding-top');
		target.style.removeProperty('padding-bottom');
		target.style.removeProperty('margin-top');
		target.style.removeProperty('margin-bottom');
		target.style.removeProperty('overflow');
		target.style.removeProperty('transition-duration');
		target.style.removeProperty('transition-property');
	}, duration);
}


document.addEventListener('DOMContentLoaded', () => {
	const promoEl = document.getElementById('promo-outer');
	if (promoEl) {
		const options = JSON.parse(promoEl.dataset.options);
		const { promoStopReappear, promo_countdown_off_on, promo_count_date, promo_count_time } = options;

		const isStopReappear = promoStopReappear === '1'; // Is Stop Reappear enabled

		const closeEl = promoEl.querySelector('#close');
		closeEl.addEventListener('click', () => {
			promoEl.classList.remove('promoOpened');
			promoEl.classList.add('promoClosed');
			slideUp(promoEl, 400);

			if (isStopReappear) {
				localStorage.setItem('bphpPromo', JSON.stringify({ closed: true }));
			} else {
				localStorage.removeItem('bphpPromo');
			}
		});

		if (isStopReappear) {
			const bphpPromoData = JSON.parse(localStorage.getItem('bphpPromo'));

			if (!bphpPromoData?.closed) {
				promoEl.style.display = 'block';
			}
		}

		// Countdown
		const isCountdown = promo_countdown_off_on === '1';
		if (isCountdown) {
			downCount(promoEl.querySelector('.countdown'), {
				date: `${promo_count_date} ${promo_count_time}`,
				// offset: +6
			});
		}

		// Remove options
		promoEl.removeAttribute('data-options');
	}
});